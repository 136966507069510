import React, { useState } from 'react';
import BreadCrumps from '../components/BreadCrumps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://a3tjsezsg5.execute-api.us-east-1.amazonaws.com/default/potential_clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (response.ok) {
        alert('Message sent successfully');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message');
    }
  };

  return (
    <div>
      <BreadCrumps page='Contáctanos' title='Contáctanos'/>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center">
            <small className="bg-primary text-white text-uppercase font-weight-bold text-center px-1">Ponte en contacto</small>
            <h1 className="mt-2 mb-5">Contáctanos para cualquier consulta</h1>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="d-flex align-items-center border mb-3 p-4">
                <i className="fa fa-2x fa-map-marker-alt text-primary mr-3"></i>
                <div className="d-flex flex-column">
                  <h5 className="font-weight-bold">Nuestras Oficinas</h5>
                  <p className="m-0">CDMX, MEX</p>
                </div>
              </div>
              <div className="d-flex align-items-center border mb-3 p-4">
                <i className="fa fa-2x fa-envelope-open text-primary mr-3"></i>
                <div className="d-flex flex-column">
                  <h5 className="font-weight-bold">Email</h5>
                  <p className="m-0">comunicacion@ai-co-worker.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center border mb-3 mb-md-0 p-4">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" className="text-primary mr-3" />
                <div className="d-flex flex-column">
                  <h5 className="font-weight-bold">Llámanos</h5>
                  <p className="m-0">+52 56 2005 5039</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="contact-form">
                <div id="success"></div>
                <form name="sentMessage" id="contactForm" noValidate="novalidate" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          type="text"
                          className="form-control p-4"
                          id="name"
                          placeholder="Tu nombre"
                          required="required"
                          data-validation-required-message="Por favor, ingrese su nombre"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          type="email"
                          className="form-control p-4"
                          id="email"
                          placeholder="Tu email"
                          required="required"
                          data-validation-required-message="Por favor, ingrese su mail"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <input
                      type="text"
                      className="form-control p-4"
                      id="subject"
                      placeholder="Asunto"
                      required="required"
                      data-validation-required-message="Por favor, ingrese el asunto"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <textarea
                      className="form-control"
                      rows="5"
                      id="message"
                      placeholder="Mensaje"
                      required="required"
                      data-validation-required-message="Por favor, ingrese su mensaje"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary font-weight-semi-bold px-4"
                      style={{ height: '50px' }}
                      type="submit"
                      id="sendMessageButton"
                    >
                      Enviar Mensaje
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
