/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function Header() {
  return (
    <div>
      {/* Navbar Start */}
      <div className="container-fluid nav-bar p-0">
        <div className="container-lg p-0">
          <nav className="navbar navbar-expand-lg bg-secondary navbar-dark">
            <a className="navbar-brand" href="/">
              <img
                src="/assets/img/logo_ai_coworker.png"
                width="140"
                height="120"
                alt="Logo AI Coworker"
                style={{ verticalAlign: 'middle' }}
              />
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
              <div className="navbar-nav">
                <a href="/" className="nav-item nav-link active">
                  Inicio
                </a>
                <a href="/about" className="nav-item nav-link">
                  Nosotros
                </a>
                <a href="/services" className="nav-item nav-link">
                  Servicios
                </a>
                <div className="nav-item dropdown">
                  <a
                    href="/products"
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Productos
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a href="/products/custom" className="dropdown-item">
                      Personalizados
                    </a>
                    <a href="/products/own" className="dropdown-item">
                      Propios
                    </a>
                    <a href="/products/consultacy" className="dropdown-item">
                      Consultoría
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href="/products" className="dropdown-item">
                      Todos los Productos
                    </a>
                  </div>
                </div>
                <a href="/contact" className="nav-item nav-link">
                  Contáctanos
                </a>
                <a href="/login" className="nav-item nav-link">
                  Login
                </a>
                <a href="/schedule" className="btn-demo nav-item nav-link">
                  Agenda una demo
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* Navbar End */}
    </div>
  );
}

export default Header;
