/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import BreadCrumps from '../components/BreadCrumps';

function Products() {
  return (
    <div>
      
      {/* Page Header Start */}
      <BreadCrumps page='Productos' title='Productos'/>
      {/* Page Header End */}

      {/* Pricing Plan Start */}
      <div className="container-fluid pt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 text-center mb-5">
                        <small className="d-inline bg-primary text-white text-uppercase font-weight-bold px-1">Nuestros Productos</small>
                        <h1 className="mt-2 mb-3">Ofrecemos productos de calidad</h1>
                        <h4 className="font-weight-normal text-muted mb-4">Nuestros paquetes son a medida de tus necesidades.</h4>
                        <h5 className="font-weight-bold mb-4">¿Necesitas un Paquete personalizado?</h5>
                        <Link to="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Contáctanos</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-5">
                        <div className="d-flex flex-column align-items-center justify-content-center bg-primary p-4">
                            <h3>Personalizado</h3>
                        </div>
                        <div className="border border-top-0 d-flex flex-column align-items-center py-4">
                            <p>Modelos de AI/ML para tu negocio.</p>
                            <p>Desarrollo web/app.</p>
                            <p>Generación de base de datos estándar.</p>
                            <p>Entre otros.</p>
                            <a href="https://wa.me/message/4Q6PHWX3V7CIA1" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold my-2">Conoce más</a>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="d-flex flex-column align-items-center justify-content-center bg-primary p-4">
                            <h3>Productos propios</h3>
                        </div>
                        <div className="border border-top-0 d-flex flex-column align-items-center py-4">
                            <p>AI Waiter.</p>
                            <p>AI Accountant.</p>
                            <p>AI Finance.</p>
                            <p>Entre otros.</p>
                            <a href="https://wa.me/message/4Q6PHWX3V7CIA1" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold my-2">Conoce más</a>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="d-flex flex-column align-items-center justify-content-center bg-primary p-4">
                            <h3>Consultoría</h3>
                        </div>
                        <div className="border border-top-0 d-flex flex-column align-items-center py-4">
                            <p>Full Stack.</p>
                            <p>Data Engineer.</p>
                            <p>Data Scientist.</p>
                            <p>Entre otros.</p>
                            <a href="https://wa.me/message/4Q6PHWX3V7CIA1" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold my-2">Conoce más</a>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      {/* Pricing Plan End */}
    </div>
  )
}

export default Products