import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Custom from './pages/products/Custom';
import Own from './pages/products/Own';
import Consultacy from './pages/products/Consultacy';
import Services from './pages/Services';
import Login from './pages/Login';
import ProductDashboard from './pages/productView/ProductDashboard';
import './App.css'; // CSS global
import Schedule from './pages/Schedule';

function AppContent() {
  const location = useLocation();
  const isProductDashboard = location.pathname.startsWith('/productsView');

  return (
    <>
      {!isProductDashboard && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/custom" element={<Custom />} />
        <Route path="/products/own" element={<Own />} />
        <Route path="/products/consultacy" element={<Consultacy />} />
        <Route path="/services" element={<Services />} />
        <Route path="/schedule" element={<Schedule />} /> 
        <Route path="/login" element={<Login />} /> 
        <Route path="/productsView/*" element={<ProductDashboard />} />{/* Nueva ruta */}
      </Routes>
      {!isProductDashboard && <Footer />}
    </>
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;
