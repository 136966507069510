import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { FaTachometerAlt, FaRobot } from 'react-icons/fa';
import Tableros from './products/Dashboard';
import IAAccountant from './products/AIAccountant';
import '../../ProductDashboard.css'; // Importa el archivo CSS separado

function ProductDashboard() {
  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <div className="sidebar-menu">
          <h2>Productos</h2>
          <ul>
            <li>
              <Link to="tableros">
                <FaTachometerAlt /> Tableros
              </Link>
            </li>
            <li>
              <Link to="ia-accountant">
                <FaRobot /> IA Accountant
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="content">
        <Routes>
          <Route path="tableros" element={<Tableros />} />
          <Route path="ia-accountant" element={<IAAccountant />} />
          <Route path="/" element={<h1>Selecciona un producto del menú para verlo.</h1>} />
        </Routes>
      </div>
    </div>
  );
}

export default ProductDashboard;
