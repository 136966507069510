import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumps from '../components/BreadCrumps';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const response = await fetch('https://tu-api-endpoint.com/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();

    if (response.ok) {
      localStorage.setItem('token', data.token);
      navigate('/dashboard'); // Redirige al dashboard de productos después de iniciar sesión
    } else {
      setError(data.message || 'Error al iniciar sesión');
    }
  };

  return (
    <div>
      {/* BreadCrumps Start */}
      <BreadCrumps page="Iniciar Sesión" title="Iniciar Sesión" />
      {/* BreadCrumps End */}
      
      {/* Contenedor del Login */}
      <div className="login-container">
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico:</label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          <div className="button-container">
            <button type="submit" className="btn btn-primary">Iniciar Sesión</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
