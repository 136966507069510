/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BreadCrumps from '../components/BreadCrumps';

function Schedule() {
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState('09:00');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [reservedSessions, setReservedSessions] = useState([]);
  
    useEffect(() => {
      // Verificar si todos los campos están llenos y el email es válido
      if (name && company && email && validateEmail(email)) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }, [name, company, email]);
  
    useEffect(() => {
      fetchReservedSessions();
    }, []);
  
    const fetchReservedSessions = async () => {
      try {
        const response = await fetch('https://on1grgbvkj.execute-api.us-east-1.amazonaws.com/get-schedule-session-potential-clients', {
          method: 'GET'
        });
        const data = await response.json();
        setReservedSessions(data.reserved_sessions);
      } catch (error) {
        console.error('Error fetching reserved sessions:', error);
      }
    };
  
    const handleDateChange = (date) => {
      setDate(date);
    };
  
    const handleTimeChange = (event) => {
      setTime(event.target.value);
    };
  
    const handleSchedule = async () => {
      const sessionData = {
        name,
        company,
        email,
        date: date.toLocaleDateString(),
        time
      };
  
      try {
        const response = await fetch('https://oqypbggx4i.execute-api.us-east-1.amazonaws.com/default/schedule-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sessionData)
        });
  
        if (response.ok) {
          alert('Sesión agendada exitosamente!');
          // Limpiar los campos
          setName('');
          setCompany('');
          setEmail('');
          setTime('09:00');
          setDate(new Date());
          // Refrescar las sesiones reservadas
          fetchReservedSessions();
        } else {
          alert('Hubo un error al agendar la sesión.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Hubo un error al agendar la sesión.');
      }
    };
  
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };
  
    const isDateReserved = (date) => {
      return reservedSessions.some(session => session.date === date.toLocaleDateString());
    };
  
    const isTimeReserved = (date, time) => {
      return reservedSessions.some(session => session.date === date.toLocaleDateString() && session.time === time);
    };
  
    return (
      <div>
        {/* Page Header Start */}
        <BreadCrumps page="Agendar Sesión" title="Agendar Sesión" />
        {/* Page Header End */}
  
        {/* Calendar Start */}
        <div className="container py-5">
          <h1 className="mb-4 text-center">Agendar Sesión</h1>
          <p className="text-center mb-4">Seleccione una fecha y hora para agendar su sesión con AI Coworker.</p>
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center">
              <Calendar
                onChange={handleDateChange}
                value={date}
                tileDisabled={({ date }) => isDateReserved(date)}
                className="custom-calendar"
              />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="time-select" className="mr-2">Seleccione la hora:</label>
                <select id="time-select" className="form-control" value={time} onChange={handleTimeChange}>
                  {Array.from({ length: 10 }, (_, i) => {
                    const hour = 9 + i;
                    const timeString = hour < 10 ? `0${hour}:00` : `${hour}:00`;
                    return <option key={timeString} value={timeString} disabled={isTimeReserved(date, timeString)}>{timeString}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Nombre:</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Empresa:</label>
                <input
                  type="text"
                  id="company"
                  className="form-control"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo:</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {!validateEmail(email) && email !== '' && (
                  <small className="text-danger">Correo electrónico no válido</small>
                )}
              </div>
              <div className="text-right">
                <button className="btn btn-primary" onClick={handleSchedule} disabled={!isFormValid}>Agendar Sesión</button>
              </div>
            </div>
          </div>
        </div>
        {/* Calendar End */}
      </div>
    );
  }
  
  export default Schedule;