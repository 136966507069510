/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTiktok, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div>
      <div className="container-fluid bg-secondary text-white mt-5 pt-5 px-sm-3 px-md-5">
        <div className="row pt-5">
          <div className="col-lg-3 col-md-6 mb-5">
            <a href="#" className="navbar-brand">
              <p style={{ textAlign: 'center' }}>
                <img 
                  src="/assets/img/logo_ai_coworker.png"
                  width="140"
                  height="120"
                  alt="Logo"
                />
              </p>
            </a>
            <div className="d-flex justify-content-start mt-4">
              <a 
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0" 
                style={{ width: '38px', height: '38px' }} 
                href="https://wa.me/message/4Q6PHWX3V7CIA1"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              </a>
              <a 
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0" 
                style={{ width: '38px', height: '38px' }} 
                href="https://www.tiktok.com/@ai.coworker?_t=8oQpO4zYATO&_r=1"
              >
                <FontAwesomeIcon icon={faTiktok} size="lg" />
              </a>
              <a 
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0" 
                style={{ width: '38px', height: '38px' }} 
                href="https://www.linkedin.com/company/ai-coworker/"
              >
                <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
              </a>
              <a 
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0" 
                style={{ width: '38px', height: '38px' }} 
                href="https://www.instagram.com/ai.coworker?igsh=OWltNjAyam82emRv"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h5 className="font-weight-bold text-primary mb-4">Links relevantes</h5>
            <div className="d-flex flex-column justify-content-start">
              <a className="text-white mb-2" href="/">
                <i className="fa fa-angle-right text-primary mr-2"></i>Inicio
              </a>
              <a className="text-white mb-2" href="/about">
                <i className="fa fa-angle-right text-primary mr-2"></i>Nosotros
              </a>
              <a className="text-white mb-2" href="/services">
                <i className="fa fa-angle-right text-primary mr-2"></i>Servicios
              </a>
              <a className="text-white mb-2" href="/products">
                <i className="fa fa-angle-right text-primary mr-2"></i>Productos
              </a>
              <a className="text-white" href="/contact">
                <i className="fa fa-angle-right text-primary mr-2"></i>Contáctanos
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h5 className="font-weight-bold text-primary mb-4">Mantente en contacto</h5>
            <p>Nosotros te guiaremos paso a paso</p>
            <p>
              <i className="fa fa-phone text-primary mr-2"></i>+52 56 2005 5039
            </p>
            <p>
              <i className="fa fa-envelope text-primary mr-2"></i>comunicacion@ai-co-worker.com
            </p>
          </div>
        </div>
        <div className="container-fluid py-4 px-sm-3 px-md-5">
          <p className="m-0 text-center">
            &copy; <a className="font-weight-semi-bold" href="#">AI Coworker</a>. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
