import React from 'react'
import BreadCrumps from '../../components/BreadCrumps'

function Consultacy() {
  return (
    <div>

      {/* Page Header Start */}
      <BreadCrumps page='Productos consultoría' title='Consultoría'/>
      {/* Page Header End */}

      {/* Services Start */}
      <div className="container py-5">
      <h1 className="mb-4">Consultoría</h1>
      <p className="mb-4">
        Conoce nuestros productos de consultoría, puedes encontrar un colaborador a la medida para tu 
        organización y tu planificación.
      </p>
      <p>
        Con nuestro servicio de consultoría puedes explorar, explotar y usar tus datos de mejor manera.
        No solo nos enfocamos a un solo rubro sino que aportamos a todos los rubros donde se necesitan tomar
        decisiones basadas en datos.
      </p>
    </div>
      {/* Services End */}


      {/* List consultacy Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row align-items-center pb-1">
            <div className="col-lg-7 mt-5 mt-lg-0">
            <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Necesitas un servicio de datos?</small>
            <h1 className="mt-2 mb-3">Nuestros Servicios</h1>
            <h4 className="font-weight-normal text-muted mb-4">Con la experiencia de nuestros colaboradores podrás hacer tus sueños realidad. Contando con los servicios de:</h4>
              <div className="list-inline mb-4">
                <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Especialistas en IA y Gen-AI</p>
                <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Especialistas en base de datos</p>
                <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Especialistas en Gobierno de datos</p>
                <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Especialistas en el analísis</p>
              </div>
              <a href="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Conoce más</a>
            </div>
            <div className="col-lg-5">
              <img className="img-thumbnail p-3" src="/assets/img/products/consultancy.jpg" alt="About Us"/>
            </div>
          </div>
        </div>
      </div>
      {/* List consultacy End */}   
    </div>
  )
}

export default Consultacy
