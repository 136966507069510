import React from 'react'
import BreadCrumps from '../../components/BreadCrumps'

function Own() {
  return (
    <div>

      {/* Page Header Start */}
      <BreadCrumps page='Productos propios' title='Propios'/>
      {/* Page Header End */}

      {/* Services Start */}
      <div className="container py-5">
      <h1 className="mb-4">Nuestros productos</h1>
      <p className="mb-4">
        Con nuestros productos optimiza tus procesos, conócelos y dejate sorprender por la eficiencia que puede generar a tu negocio.
      </p>
      <p>
        Con nuestros productos se más eficiente en tu negocio y genera mayor rentabilidad usandolos. Estos tres productos son pensandos para poder aumentar
        la eficiencia de nuestros partners y con ellos ocupar su tiempo para algo más grande.
      </p>
      </div>
      {/* Services End */}

      {/* AI Waiter Start */}
      <div className="container-fluid py-5">
            <div className="container">
                <div className="row align-items-center pb-1">
                    <div className="col-lg-5">
                        <img className="img-thumbnail p-3" src="/assets/img/products/AI Waiter.png" alt="About Us"/>
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Necesitas un mesero?</small>
                        <h1 className="mt-2 mb-4">AI Waiter</h1>
                        <p className="mb-4">AI Waiter es tu mesero personalizado, conectamos tu base de datos con un mesero con distintas opciones para que puedas ofrecer tus platillos de forma más rápida y reduciendo los costes.</p>
                        <a href="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Agenda una Demo</a>
                    </div>
                </div>
            </div>
      </div>
      {/* AI Waiter End */}

      {/* AI Accountant Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row align-items-center pb-1">
            <div className="col-lg-7 mt-5 mt-lg-0">
              <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Necesitas un contador?</small>
              <h1 className="mt-2 mb-4">AI Accountant</h1>
              <p className="mb-4">AI Accountant es tu contador personalizado, por medio de tus facturas, estados de cuenta, entre otros podemos ayudarte a llevar la contabilidad de manera más eficiente y reduciendo los costos.</p>
              <a href="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Agenda una Demo</a>
            </div>
            <div className="col-lg-5">
              <img className="img-thumbnail p-3" src="/assets/img/products/AI Accountant.jpg" alt="About Us"/>
            </div>
          </div>
        </div>
      </div>
      {/* AI Accountant End */}      

    
    </div>
  )
}

export default Own
