/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BreadCrumps from '../components/BreadCrumps'

function Services() {
  return (
    <div>

      {/* Page Header Start */}
      <BreadCrumps page='Servicios' title='Servicios'/>
      {/* Page Header End */}

      {/* Services Start */}
      <div className="container-fluid pt-5 pb-3">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center mb-5">
                <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Qué hacemos?</small>
                <h1 className="mt-2 mb-3">Ofrecemos soluciones creativas con AI</h1>
                <h4 className="font-weight-normal text-muted mb-4">
                Brindamos a las distintas empresas las herramientas para uso de datos y explotar estos mismos.
                </h4>
                <a href="https://wa.me/message/4Q6PHWX3V7CIA1" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">
                Descubre más
                </a>
            </div>
            </div>
            <div className="row">
            <div className="col-md-6 mb-5">
                <div className="d-flex">
                <i className="fa fa-3x fa-laptop-code text-primary mr-4"></i>
                <div className="d-flex flex-column">
                    <h4 className="font-weight-bold mb-3">Diseño Web/App</h4>
                    <p>Creación de páginas webs o aplicaciones interconectadas.</p>
                    <a className="font-weight-semi-bold" href="/products/custom">
                    Ver más <i className="fa fa-angle-double-right"></i>
                    </a>
                </div>
                </div>
            </div>
            <div className="col-md-6 mb-5">
                <div className="d-flex">
                <i className="fa fa-3x fa-envelope-open-text text-primary mr-4"></i>
                <div className="d-flex flex-column">
                    <h4 className="font-weight-bold mb-3">Big Data</h4>
                    <p>Diseño de soluciones de arquitectura, bases de datos y procesos ETL.</p>
                    <a className="font-weight-semi-bold" href="/products/custom">
                    Ver más <i className="fa fa-angle-double-right"></i>
                    </a>
                </div>
                </div>
            </div>
            <div className="col-md-6 mb-5">
                <div className="d-flex">
                <i className="fa fa-3x fa-envelope-open-text text-primary mr-4"></i>
                <div className="d-flex flex-column">
                    <h4 className="font-weight-bold mb-3">AI Software</h4>
                    <p>Diseño de software a la medida para mejorar tu negocio con AI.</p>
                    <a className="font-weight-semi-bold" href="/products/custom">
                    Ver más <i className="fa fa-angle-double-right"></i>
                    </a>
                </div>
                </div>
            </div>
            <div className="col-md-6 mb-5">
                <div className="d-flex">
                <i className="fa fa-3x fa-chart-line text-primary mr-4"></i>
                <div className="d-flex flex-column">
                    <h4 className="font-weight-bold mb-3">Inteligencia de Negocio</h4>
                    <p>Con nuestros tableros aplicarás la inteligencia de negocio para mejorar tus métricas.</p>
                    <a className="font-weight-semi-bold" href="/products/custom">
                    Ver más <i className="fa fa-angle-double-right"></i>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

      {/* Services End */}
    </div>
  )
}

export default Services