/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import BreadCrumps from '../../components/BreadCrumps';

function Custom() {
  return (
    <div>
      {/* Page Header Start */}
      <BreadCrumps page="Productos personalizados" title="Personalizado" />
      {/* Page Header End */}

      {/* Products List Start */}
      <div className="container py-5">
        <h1 className="mb-4">Productos Personalizados</h1>
        <p className="text-center mb-4">Hacemos tus sueños realidad con AI Coworker.</p>
        <div className="product-list">
          <div className="product-item d-flex align-items-center mb-4">
            <img
              className="img-fluid mr-4"
              src="/assets/img/products/AI_draw.jpg"
              alt="AI/ML personilze product"
              style={{ width: '100px', height: '100px' }}
            />
            <div>
              <h4 className="mb-2">Modelos de AI/ML</h4>
              <p className="mb-0">
                Diseñamos modelos a la medida. Con esta analítica avanzada llevarás tu negocio al siguiente nivel, explotando tus datos y realizando predicciones con estos mismos. Con modelos como LLMs, XGBoost, Regresión Lineal, Decision Tree, K-Means, entre otros.
              </p>
            </div>
          </div>
          <div className="product-item d-flex align-items-center mb-4">
            <img
              className="img-fluid mr-4"
              src="/assets/img/products/WebApp_draw.jpg"
              alt="Web/App personalize product"
              style={{ width: '100px', height: '100px' }}
            />
            <div>
              <h4 className="mb-2">Desarrollo Web/App</h4>
              <p className="mb-0">
                Hacemos tus sueños realidad diseñando una web/app a tu medida, desde aplicaciones IOS y Android, o con una web para dar a conocer mejor tus servicios a tus clientes.
              </p>
            </div>
          </div>
          <div className="product-item d-flex align-items-center mb-4">
            <img
              className="img-fluid mr-4"
              src="/assets/img/products/DataBases_draw.jpg"
              alt="Data Bases personalize product"
              style={{ width: '100px', height: '100px' }}
            />
            <div>
              <h4 className="mb-2">Bases de datos</h4>
              <p className="mb-0">
                ¿Necesitas una base de datos? Con nosotros puedes integrar tu front con una base de datos para recabar la información necesaria y poder explotar estos datos.
              </p>
            </div>
          </div>
          <div className="product-item d-flex align-items-center mb-4">
            <img
              className="img-fluid mr-4"
              src="/assets/img/products/Dashboards_draw.jpg"
              alt="Dashboards personalize product"
              style={{ width: '100px', height: '100px' }}
            />
            <div>
              <h4 className="mb-2">Tableros</h4>
              <p className="mb-0">
                Las mejores decisiones se toman con datos. Nuestros tableros te ayudan a visualizar de manera correcta tus datos y poder usarlos en tu negocio.
              </p>
            </div>
          </div>
        </div>
        {/* Botón para contactar */}
        <div className="text-center mt-5">
          <a href="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">
            Contáctanos
          </a>
        </div>
      </div>
      {/* Products List End */}
    </div>
  );
}

export default Custom;

